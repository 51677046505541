import React, { Component } from "react";
import { connect } from "react-redux";

import LaddaButton, { EXPAND_RIGHT, S } from "react-ladda";
import { Button, Card, CardBlock, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import AlertMessageClass from "../../../shared/components/alert_message";
import StateListClass from "../../../shared/components/state_list";
import "./style.css";

import MasterTableClass from "../../../shared/components/question_table/master_table";
import QuestionTableClass from "../../../shared/components/question_table/question_table";

import LoaderClass from "../../../shared/components/loader";
// import Editable,{CustomComponent} from 'react-x-editable';

import { fetchAreaRiskTypeList, fetchStateList } from "../../actions/actions";
import {
  addNewQuestion,
  addNewQuestionWhere,
  addNewQuestionWhy,
  addQuestionConfigData,
  fetchQuestionCategoryList,
  fetchQuestionList,
  fetchQuestionWhereList,
  fetchQuestionWhyList,
  getQuestionById,
  getQuestionConfigById,
  getWhereById,
  getWhyById,
  removeQuestion,
  removeWhereComment,
  removeWhyComment,
  updateQuestionById,
  updateQuestionConfigById,
  updateWhereById,
  updateWhyById,
} from "../../actions/questions_actions";

//import {Table, Th, Thead} from 'reactable';
var axios = require("axios");
var simpleReactValidator = require("simple-react-validator");

class Questions extends Component {
  /*all event and state*/
  constructor(props) {
    super(props);
    this.validator = new simpleReactValidator(
      (this.rules = {
        custom_text: {
          message: "The :attribute must be a valid text.",
          rule: (val) => !this._testRegex(val, /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/),
        },
      })
    );
    this.state = {
      data: [],
      state_data: [],
      question_data: [],
      drpStateValue: "",
      drpFacilityValue: "",
      drpQuestionValue: "",
      newData: "",
      stateId: "",
      modal: false,
      newQus: true,
      alertMessageText: "",
      alertMessageVisible: true,
      alertMessageColor: "",
      loading: false,
      btnAddloading: false,
      btnAddloading2: false,
      btnEditloading: false,
      updateModel: false,
      qus_id: "",
      question_text: "",
      question_info: "",
      editQusData: "",
      errorEmail: false,
      qusForm: true,
      qusConfigForm: true,
      btnName: "Add",
      loaderStatus: true,
      setQusFocus: false,
      setInfoFocus: false,
      showDefaultTable: false,
      showMasterTable: true,
      masForm: true,
      disMasForm: true,
      popWhyModal: false,
      whyModalClass: "",
      popWhereModal: false,
      whereModalClass: "",
      why_name: "",
      where_name: "",
      btnNameWhy: "Add",
      btnNameWhere: "Add",
      deleteModal: false,
      del_modal_name: "",
      del_ques_id: "",
      del_why_id: "",
      del_where_id: "",
      fetch_question_by_id: [],
      question_where_data: [],
      question_why_data: [],
      master_que_text: "",
      setWhyFocus: false,
      setWhereFocus: false,
      whyFlag: true,
      btnWhyName: "Add",
      why_id: "",
      whereFlag: true,
      btnWhereName: "Add",
      where_id: "",
      fetch_where_by_id: [],
      fetch_why_by_id: [],
      drpCatValue: "",
      risk_cat_id: "",
      risk_cat_arr: [],
      fetch_question_config_by_id: [],
      config_flag: false,
      is_valid_que_text: false,
      is_valid_help_text: false,
      is_valid_why_text: false,
      is_valid_where_text: false,
      undefined_msg: false,
    };

    this.drpClickState = this.drpClickState.bind(this);

    this.btnLoadQuestion = this.btnLoadQuestion.bind(this);

    this.btnLoadMaster = this.btnLoadMaster.bind(this);

    this.btnLoadComment = this.btnLoadComment.bind(this);

    this.changeTxtAddQues = this.changeTxtAddQues.bind(this);

    this.toggleConfigureModal = this.toggleConfigureModal.bind(this);

    this.changeTxtAddQusInfo = this.changeTxtAddQusInfo.bind(this);

    this.changeTxtAddWhy = this.changeTxtAddWhy.bind(this);

    this.changeTxtAddWhere = this.changeTxtAddWhere.bind(this);

    this.clickBtnAddQus = this.clickBtnAddQus.bind(this);

    this.onDismiss = this.onDismiss.bind(this);

    this.toggle = this.toggle.bind(this);

    this.btnAddloading2 = this.btnAddloading2.bind(this);

    this.btnAddloading = this.btnAddloading.bind(this);

    this.btnEditloading = this.btnEditloading.bind(this);

    this.updateModal = this.updateModal.bind(this);

    this.toogleModelClose = this.toogleModelClose.bind(this);

    this.toogleConfigModelClose = this.toogleConfigModelClose.bind(this);

    this.AddtoogleQuestionModel = this.AddtoogleQuestionModel.bind(this);

    this.backToQueList = this.backToQueList.bind(this);

    this.toggleWhyModal = this.toggleWhyModal.bind(this);

    this.toggleWhereModal = this.toggleWhereModal.bind(this);

    this.clickBtnAddWhy = this.clickBtnAddWhy.bind(this);

    this.clickBtnAddWhere = this.clickBtnAddWhere.bind(this);

    this.toogleWhyModelClose = this.toogleWhyModelClose.bind(this);

    this.toogleWhereModelClose = this.toogleWhereModelClose.bind(this);

    this.drpChangeCat = this.drpChangeCat.bind(this);

    this.clickBtnAddConfigData = this.clickBtnAddConfigData.bind(this);
  }

  _testRegex(value, regex) {
    return value.toString().match(regex) !== null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.question_data != prevProps.question_data) {
      this.setState({
        loading: false,
        loaderStatus: true,
      });
    }

    if (this.props.question_add_data != prevProps.question_add_data) {
      this.setState({
        btnAddloading: false,
      });

      if (this.props.question_add_data != null && this.props.question_add_data != "") {
        this.refreshQuestion();
        if (this.props.question_add_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Record successfully inserted",
            qusForm: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
            qusForm: true,
          });
        }
      }
    }

    if (this.props.question_delete_data != prevProps.question_delete_data) {
      if (this.props.question_delete_data != null && this.props.question_delete_data != "") {
        this.refreshQuestion();
        if (this.props.question_delete_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Record successfully deleted",
            qusForm: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.fetch_question_by_id != prevProps.fetch_question_by_id) {
      //console.log(this.props.fetch_question_by_id);
      if (this.props.fetch_question_by_id != null && this.props.fetch_question_by_id != "") {
        var que_text = this.props.fetch_question_by_id.result[0].que_text;
        var que_info = this.props.fetch_question_by_id.result[0].que_info;

        this.setState({
          question_text: que_text,
          question_info: que_info,
          master_que_text: que_text,
        });
      }
    }

    if (this.props.question_update_data != prevProps.question_update_data) {
      if (this.props.question_update_data != null && this.props.question_update_data != "") {
        this.refreshQuestion();
        if (this.props.question_update_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Record successfully updated",
            qusForm: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.question_add_where_data != prevProps.question_add_where_data) {
      if (this.props.question_add_where_data != null && this.props.question_add_where_data != "") {
        this.refreshQuestionWhere();
        if (this.props.question_add_where_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Record successfully inserted",
            whereFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.where_delete_data != prevProps.where_delete_data) {
      if (this.props.where_delete_data != null && this.props.where_delete_data != "") {
        this.refreshQuestionWhere();
        if (this.props.where_delete_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Record successfully deleted",
            whereFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.fetch_where_by_id != prevProps.fetch_where_by_id) {
      //console.log(this.props.fetch_where_by_id);
      if (this.props.fetch_where_by_id != null && this.props.fetch_where_by_id != "") {
        var where_comment = this.props.fetch_where_by_id.result[0].where_comment;

        this.setState({
          where_name: where_comment,
        });
      }
    }

    if (this.props.where_update_data != prevProps.where_update_data) {
      if (this.props.where_update_data != null && this.props.where_update_data != "") {
        this.refreshQuestionWhere();
        if (this.props.where_update_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Record successfully updated",
            whereFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.question_add_why_data != prevProps.question_add_why_data) {
      if (this.props.question_add_why_data != null && this.props.question_add_why_data != "") {
        this.refreshQuestionWhy();
        if (this.props.question_add_why_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Record successfully inserted",
            whyFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.why_delete_data != prevProps.why_delete_data) {
      if (this.props.why_delete_data != null && this.props.why_delete_data != "") {
        this.refreshQuestionWhy();
        if (this.props.why_delete_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Record successfully deleted",
            whyFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.fetch_why_by_id != prevProps.fetch_why_by_id) {
      console.log(this.props.fetch_why_by_id);
      if (this.props.fetch_why_by_id != null && this.props.fetch_why_by_id != "") {
        var why_comment = this.props.fetch_why_by_id.result[0].why_comment;
        this.setState({
          why_name: why_comment,
        });
      }
    }

    if (this.props.why_update_data != prevProps.why_update_data) {
      if (this.props.why_update_data != null && this.props.why_update_data != "") {
        this.refreshQuestionWhy();
        if (this.props.why_update_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Record successfully updated",
            whyFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.question_add_config_data != prevProps.question_add_config_data) {
      this.setState({
        btnAddloading: false,
      });

      if (this.props.question_add_config_data != null && this.props.question_add_config_data != "") {
        //this.refreshQuestion();
        if (this.props.question_add_config_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Question data configured successfully",
            qusConfigForm: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
            qusConfigForm: true,
          });
        }
      }
    }

    if (this.props.question_update_config_data != prevProps.question_update_config_data) {
      this.setState({
        btnAddloading: false,
      });

      if (this.props.question_update_config_data != null && this.props.question_update_config_data != "") {
        //this.refreshQuestion();
        if (this.props.question_update_config_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Question data configured successfully",
            qusConfigForm: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
            qusConfigForm: true,
          });
        }
      }
    }

    if (this.props.fetch_question_config_by_id != prevProps.fetch_question_config_by_id) {
      var that = this;
      var qus_id = this.state.qus_id;
      if (this.props.fetch_question_config_by_id != null && this.props.fetch_question_config_by_id != "") {
        var is_success = this.props.fetch_question_config_by_id.success;
        console.log("result: " + is_success); // true / false
        if (is_success) {
          var cat_arr_count = this.props.fetch_question_config_by_id.result.length;
          // loop
          for (var i = 0; i < cat_arr_count; i++) {
            var area_risk_type_id = this.props.fetch_question_config_by_id.result[i].area_risk_type_id;
            var que_cat_id = this.props.fetch_question_config_by_id.result[i].que_cat_id;
            var risk_cat_id = area_risk_type_id + "-" + que_cat_id; // 1-3
            that.state.risk_cat_arr[area_risk_type_id] = risk_cat_id;
          }
        } else {
          // make state null
          that.setState({ risk_cat_arr: [] });
        }
        that.setState({ config_flag: is_success });
        console.log("after_risk_cat_arr: " + this.state.risk_cat_arr);
      }
    }
  }

  componentDidMount() {
    var id = sessionStorage.getItem("role_id");
    console.log(id);
    const { dispatch } = this.props;
    dispatch(fetchStateList(id));
    //this.props.dispatch(fetchAreaRiskTypeList());
    this.props.dispatch(fetchQuestionCategoryList());
  }

  toggle() {
    this.setState({
      loading: !this.state.loading,
      progress: 0.5,
    });
  }
  btnAddloading() {
    this.setState({
      btnAddloading: !this.state.btnAddloading,
      progress: 0.5,
    });
  }
  btnAddloading2() {
    this.setState({
      btnAddloading2: !this.state.btnAddloading2,
      progress: 0.5,
    });
  }

  btnEditloading() {
    this.setState({
      btnEditloading: !this.state.btnEditloading,
      progress: 0.5,
    });
  }
  toogleModelClose() {
    this.setState({
      qusForm: true,
    });
  }
  toogleConfigModelClose() {
    this.setState({
      qusConfigForm: true,
    });
  }
  AddtoogleQuestionModel(e) {
    var get_value = e.target.value;
    //console.log();
    if (get_value === "btnadd")
      this.setState({
        qus_id: "",
        qusForm: false,
        qusConfigForm: true,
        question_text: "",
        question_info: "",
        btnName: "Add",
        alertMessageVisible: false,
        undefined_msg: false,
      });
    this.validator.hideMessages();
  }

  // for back to question list
  backToQueList(e) {
    this.setState({
      newQus: false,
      qusForm: true,
      qusConfigForm: true,
      masForm: true,
      disMasForm: true,
      whyFlag: true,
      whereFlag: true,
      alertMessageVisible: false,
      undefined_msg: false,
    });
  }

  drpChangeCat(e) {
    var that = this;
    that.state.risk_cat_arr[e.target.id] = e.target.value;
    console.log("risk_cat_arr_change: " + that.state.risk_cat_arr);
    return this.setState({ risk_cat_arr: this.state.risk_cat_arr });
  }

  // for edit question modal
  toggleEditQuestionModal = (e) => {
    var that = this;

    var qus_id = e.target.value;
    console.log("edit " + qus_id);
    if (qus_id !== "" && qus_id !== undefined) {
      that.setState({
        qus_id: qus_id,
        btnName: "Update",
        qusForm: false,
        qusConfigForm: true,
        setQusFocus: true,
        is_valid_que_text: true,
        is_valid_help_text: true,
      });

      {
        /* that.setState({
                        btnAddloading2: !that.state.btnAddloading2,
                        progress: 0.5,
                    }); */
      }

      // call API to get question details
      this.props.dispatch(getQuestionById(qus_id));

      that.setState({
        btnAddloading2: false,
        alertMessageVisible: false,
        undefined_msg: false,
      });
    } else {
      that.setState({
        qusForm: true,
        modal: true,
        errorEmail: true,
        alertMessageVisible: false,
        undefined_msg: true,
      });
    }
  };

  // for edit why modal
  toggleEditWhyModal = (e) => {
    var that = this;

    var why_id = e.target.value;
    console.log("edit why " + why_id);
    if (why_id !== "" && why_id !== undefined) {
      that.setState({
        why_id: why_id,
        btnWhyName: "Update",
        whyFlag: false,
        whereFlag: true,
        setWhyFocus: true,
        is_valid_why_text: true,
      });

      {
        /* that.setState({
                        btnAddloading2: !that.state.btnAddloading2,
                        progress: 0.5,
                    }); */
      }

      // call API to get why comment details
      this.props.dispatch(getWhyById(why_id));

      that.setState({
        btnAddloading2: false,
        alertMessageVisible: false,
        undefined_msg: false,
      });
    } else {
      that.setState({
        modal: true,
        errorEmail: true,
        alertMessageVisible: false,
        undefined_msg: true,
      });
    }
  };

  // for edit where modal
  toggleEditWhereModal = (e) => {
    var that = this;

    var where_id = e.target.value;
    console.log("edit where " + where_id);
    if (where_id !== "" && where_id !== undefined) {
      that.setState({
        where_id: where_id,
        btnWhereName: "Update",
        whereFlag: false,
        whyFlag: true,
        setWhereFocus: true,
        is_valid_where_text: true,
      });

      {
        /* that.setState({
                        btnAddloading2: !that.state.btnAddloading2,
                        progress: 0.5,
                    }); */
      }

      // call API to get where comment details
      this.props.dispatch(getWhereById(where_id));

      that.setState({
        btnAddloading2: false,
        alertMessageVisible: false,
        undefined_msg: false,
      });
    } else {
      that.setState({
        modal: true,
        errorEmail: true,
        alertMessageVisible: false,
        undefined_msg: true,
      });
    }
  };

  updateModal = (e) => {
    this.setState({
      updateModel: !this.state.updateModel,
    });
  };

  btnLoadQuestion(e) {
    var that = this;

    var state_id = this.state.drpStateValue;
    if (state_id !== "") {
      var facility_id = "0";

      that.setState({
        loading: !this.state.loading,
        progress: 0.5,
        loaderStatus: false,
        alertMessageVisible: false,
      });

      this.props.dispatch(fetchQuestionList(state_id, facility_id));
      this.props.dispatch(fetchAreaRiskTypeList(state_id));
      that.setState({
        newQus: false,
        qusForm: true,
        qusConfigForm: true,
        masForm: true,
        disMasForm: true,
        whyFlag: true,
        whereFlag: true,
      });
    } else {
      {
        /*if(that.validator.allValid())
                {
                } 
                else 
                {
                that.validator.showMessages();
                    // rerender to show messages for the first time 
                that.forceUpdate();
                }*/
      }
      this.validator.showMessages();
      that.setState({
        newQus: true,
        qusForm: true,
        qusConfigForm: true,
        showDefaultTable: false,
        alertMessageVisible: false,
        data: [],
        errorEmail: true,
      });
    }
    this.setState({
      showDefaultTable: true,
      masForm: true,
      disMasForm: true,
      alertMessageVisible: false,
      undefined_msg: false,
    });
  }

  btnLoadMaster(e) {
    var that = this;
    var qus_id = e.target.value;
    console.log(qus_id);
    var state_id = this.state.drpStateValue;
    if (state_id !== "" && qus_id !== "" && qus_id !== undefined) {
      // call API to get question details
      this.props.dispatch(getQuestionById(qus_id));

      // call API for getting question where-why bank
      this.props.dispatch(fetchQuestionWhereList(state_id, qus_id));
      this.props.dispatch(fetchQuestionWhyList(state_id, qus_id));

      that.setState({
        newQus: true,
        qusForm: true,
        qusConfigForm: true,
        masForm: true,
        disMasForm: false,
        qus_id: qus_id,
        undefined_msg: false,
      });
    } else {
      {
        /*if(that.validator.allValid())
                {
                } 
                else 
                {
                that.validator.showMessages();
                    // rerender to show messages for the first time 
                that.forceUpdate();
                }*/
      }
      this.validator.showMessages();
      that.setState({
        newQus: true,
        qusForm: true,
        qusConfigForm: true,
        masForm: true,
        disMasForm: true,
        showDefaultTable: false,
        data: [],
        errorEmail: true,
        undefined_msg: true,
      });
    }
    this.setState({
      showDefaultTable: true,
      alertMessageVisible: false,
    });
  }

  btnLoadComment(e) {
    var that = this;

    var question_id = this.state.drpQuestionValue;
    if (question_id !== "" && question_id != undefined) {
      that.setState({
        newQus: true,
        qusForm: true,
        qusConfigForm: true,
        disMasForm: false,
        undefined_msg: false,
      });
    } else {
      {
        /*if(that.validator.allValid())
                {
                } 
                else 
                {
                that.validator.showMessages();
                    // rerender to show messages for the first time 
                that.forceUpdate();
                }*/
      }
      this.validator.showMessages();
      that.setState({
        newQus: true,
        qusForm: true,
        qusConfigForm: true,
        disMasForm: true,
        showDefaultTable: false,
        alertMessageVisible: false,
        data: [],
        errorEmail: true,
        undefined_msg: true,
      });
    }
    this.setState({
      showDefaultTable: true,
    });
  }

  refreshQuestion() {
    //var facility_id = this.state.drpFacilityValue;
    var state_id = this.state.drpStateValue;
    if (state_id !== "") {
      var facility_id = "0";
      this.props.dispatch(fetchQuestionList(state_id, facility_id));
    }
  }

  refreshQuestionWhere() {
    var state_id = this.state.drpStateValue;
    var qus_id = this.state.qus_id;
    if (state_id !== "" && qus_id !== "") {
      this.props.dispatch(fetchQuestionWhereList(state_id, qus_id));
    }
  }

  refreshQuestionWhy() {
    var state_id = this.state.drpStateValue;
    var qus_id = this.state.qus_id;
    if (state_id !== "" && qus_id !== "") {
      this.props.dispatch(fetchQuestionWhyList(state_id, qus_id));
    }
  }

  drpClickState = (e) => {
    var state_id = e.target.value;
    this.setState({
      drpStateValue: state_id,
      stateId: state_id,
    });

    if (state_id === "") {
      this.setState({
        newQus: true,
        qusForm: true,
        qusConfigForm: true,
        masForm: true,
        disMasForm: true,
        whyFlag: true,
        whereFlag: true,
        alertMessageVisible: false,
        data: [],
        showDefaultTable: false,
      });
    }
  };

  drpClickQuestion = (e) => {
    this.setState({
      drpQuestionValue: e.target.value,
    });
    var sel_question_id = e.target.value;
    if (sel_question_id === "") {
      this.setState({
        newQus: true,
        qusForm: true,
        qusConfigForm: true,
        disMasForm: true,
        alertMessageVisible: false,
        undefined_msg: false,
        data: [],
        showDefaultTable: true,
        showMasterTable: false,
      });
    }
  };

  changeTxtAddQues = (e) => {
    var that = this;
    var val = e.target.value;
    var pattern = /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/;
    if (pattern.test(val)) {
      this.setState({
        is_valid_que_text: false,
      });
    } else {
      this.setState({
        is_valid_que_text: true,
      });
    }

    this.setState({
      question_text: e.target.value,
      setQusFocus: true,
      setInfoFocus: false,
    });
  };
  changeTxtAddQusInfo = (e) => {
    var that = this;
    var val = e.target.value;
    var pattern = /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/;
    if (pattern.test(val)) {
      this.setState({
        is_valid_help_text: false,
      });
    } else {
      this.setState({
        is_valid_help_text: true,
      });
    }

    this.setState({
      question_info: e.target.value,
      setInfoFocus: true,
      setQusFocus: false,
    });
  };

  changeTxtAddWhy = (e) => {
    var that = this;
    var val = e.target.value;
    var pattern = /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/;
    if (pattern.test(val)) {
      this.setState({
        is_valid_why_text: false,
      });
    } else {
      this.setState({
        is_valid_why_text: true,
      });
    }

    this.setState({
      why_name: e.target.value,
      setWhyFocus: true,
    });
  };

  changeTxtAddWhere = (e) => {
    var that = this;
    var val = e.target.value;
    var pattern = /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/;
    if (pattern.test(val)) {
      this.setState({
        is_valid_where_text: false,
      });
    } else {
      this.setState({
        is_valid_where_text: true,
      });
    }

    this.setState({
      where_name: e.target.value,
      setWhereFocus: true,
    });
  };

  // toggleWhyModal(e)
  // {
  //     //console.log("value");
  //     console.log(e.target.value);
  // }

  toggleWhyModal(e) {
    //console.log("hi"+e.target.value);
    var get_value = e.target.value;
    if (get_value === "btnadd") {
      this.setState({
        why_name: "",
        why_id: "",
        btnWhyName: "Add",
        whyFlag: false,
        whereFlag: true,
        alertMessageVisible: false,
        undefined_msg: false,
      });
    }
    this.validator.hideMessages();
  }

  toggleWhereModal(e) {
    var get_value = e.target.value;
    if (get_value === "btnadd") {
      this.setState({
        where_name: "",
        where_id: "",
        btnWhereName: "Add",
        whereFlag: false,
        whyFlag: true,
        alertMessageVisible: false,
        undefined_msg: false,
      });
    }
    this.validator.hideMessages();
  }

  toggleDeleteModal = (e, str) => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      whereModalClass: str,
      undefined_msg: false,
    });
  };

  // for open delete modal
  toggleQuesDeleteModal = (e, str) => {
    var que_id = e.target.value;
    this.setState({
      deleteModal: !this.state.deleteModal,
      del_ques_id: que_id,
      del_modal_name: "question",
      undefined_msg: false,
    });
  };

  toggleWhyDeleteModal = (e, str) => {
    var why_id = e.target.value;
    this.setState({
      deleteModal: !this.state.deleteModal,
      del_why_id: why_id,
      del_modal_name: "why",
      undefined_msg: false,
    });
  };
  toggleWhereDeleteModal = (e, str) => {
    var where_id = e.target.value;
    this.setState({
      deleteModal: !this.state.deleteModal,
      del_where_id: where_id,
      del_modal_name: "where",
      undefined_msg: false,
    });
  };

  // for close modal
  toogleDeleteModelClose = (e) => {
    this.setState({
      deleteModal: false,
      undefined_msg: false,
    });
  };

  clickBtnDelete = (e) => {
    var del_modal_name = this.state.del_modal_name;
    console.log(del_modal_name);
    if (del_modal_name == "question") {
      // for delete question
      var del_ques_id = this.state.del_ques_id;
      this.props.dispatch(removeQuestion(del_ques_id));
    } else if (del_modal_name == "why") {
      // for delete why comment
      var del_why_id = this.state.del_why_id;
      this.props.dispatch(removeWhyComment(del_why_id));
    } else if (del_modal_name == "where") {
      // for delete where comment
      var del_where_id = this.state.del_where_id;
      this.props.dispatch(removeWhereComment(del_where_id));
    } else {
    }
    this.setState({
      deleteModal: false,
    });
  };

  clickBtnAddQus = (e) => {
    var that = this;
    //console.log(that.state.is_valid_que_text);
    //console.log(that.state.is_valid_help_text);
    if (that.validator.allValid()) {
    } else {
      that.validator.showMessages();
      // rerender to show messages for the first time
      that.forceUpdate();
    }
    var qus_id = that.state.qus_id;

    var state_id = that.state.stateId;
    var facility_id = "";
    var question_text = that.state.question_text.trim();
    var question_info = that.state.question_info.trim();

    //console.log(state_id);
    //console.log(question_text);
    //console.log(question_info);

    if (qus_id === "") {
      // add question
      if (state_id !== "" && question_text !== "" && question_info !== "" && that.state.is_valid_que_text === true && that.state.is_valid_help_text === true) {
        that.setState({
          btnAddloading: !that.state.btnAddloading,
          progress: 0.5,
        });

        // call API to add question
        var post = {
          state_id: state_id,
          facility_id: facility_id,
          que_text: question_text,
          que_info: question_info,
        };
        console.log(post);
        this.props.dispatch(addNewQuestion(post));
        that.setState({
          btnAddloading: false,
        });
      } else {
        that.setState({
          modal: true,
          errorEmail: true,
        });
      }
    } // update question
    else {
      if (state_id !== "" && question_text !== "" && question_info !== "" && that.state.is_valid_que_text === true && that.state.is_valid_help_text === true) {
        that.setState({
          btnAddloading2: !that.state.btnAddloading2,
          progress: 0.5,
        });

        var post = {
          id: qus_id,
          state_id: state_id,
          facility_id: facility_id,
          que_text: question_text,
          que_info: question_info,
        };
        console.log(post);
        // call API to update question
        this.props.dispatch(updateQuestionById(post));

        that.setState({
          btnAddloading2: false,
        });
      }
    }
  };

  clickBtnAddWhy = (e) => {
    var that = this;
    if (that.validator.allValid()) {
    } else {
      that.validator.showMessages();
      // rerender to show messages for the first time
      that.forceUpdate();
    }
    var why_id = that.state.why_id;
    var state_id = that.state.drpStateValue;
    var qus_id = that.state.qus_id;
    //console.log(why_id);
    //console.log(state_id);
    //console.log(qus_id);
    if (why_id === "") {
      // add why comment
      var why_name = that.state.why_name;
      if (state_id !== "" && qus_id !== "" && why_name !== "" && that.state.is_valid_why_text === true) {
        that.setState({
          btnAddloading2: !that.state.btnAddloading2,
          progress: 0.5,
        });

        // call API to add why comment
        var post = {
          state_id: state_id,
          que_id: qus_id,
          why_comment: why_name,
        };
        this.props.dispatch(addNewQuestionWhy(post));
        that.setState({
          btnAddloading2: false,
        });
      } else {
        that.setState({
          modal: true,
          errorEmail: true,
        });
      }
    } // update why comment
    else {
      var why_name = that.state.why_name;
      if (state_id !== "" && qus_id !== "" && why_name !== "" && that.state.is_valid_why_text === true) {
        that.setState({
          btnAddloading2: !that.state.btnAddloading2,
          progress: 0.5,
        });

        var why_name = that.state.why_name;
        var post = {
          state_id: state_id,
          que_id: qus_id,
          why_comment: why_name,
          id: why_id,
        };
        console.log(post);
        // call API to update why comment
        this.props.dispatch(updateWhyById(post));

        that.setState({
          btnAddloading2: false,
        });
      }
    }
  };

  clickBtnAddWhere = (e) => {
    var that = this;
    if (that.validator.allValid()) {
    } else {
      that.validator.showMessages();
      // rerender to show messages for the first time
      that.forceUpdate();
    }
    var where_id = that.state.where_id;
    var state_id = that.state.drpStateValue;
    var qus_id = that.state.qus_id;
    //console.log(state_id);
    //console.log(qus_id);
    if (where_id === "") {
      // add where comment
      var where_name = that.state.where_name;
      if (state_id !== "" && qus_id !== "" && where_name !== "" && that.state.is_valid_where_text === true) {
        that.setState({
          btnAddloading2: !that.state.btnAddloading2,
          progress: 0.5,
        });

        // call API to add where comment
        var post = {
          state_id: state_id,
          que_id: qus_id,
          where_comment: where_name,
        };
        this.props.dispatch(addNewQuestionWhere(post));
        that.setState({
          btnAddloading2: false,
        });
      } else {
        that.setState({
          modal: true,
          errorEmail: true,
        });
      }
    } // update where comment
    else {
      var where_name = that.state.where_name;
      if (state_id !== "" && qus_id !== "" && where_name !== "" && that.state.is_valid_where_text === true) {
        that.setState({
          btnAddloading2: !that.state.btnAddloading2,
          progress: 0.5,
        });

        var where_name = that.state.where_name;
        var post = {
          state_id: state_id,
          que_id: qus_id,
          where_comment: where_name,
          id: where_id,
        };
        //console.log(post);

        // call API to update where comment
        this.props.dispatch(updateWhereById(post));

        that.setState({
          btnAddloading2: false,
        });
      }
    }
  };

  // for open question configure modal
  toggleConfigureModal = (e) => {
    var that = this;
    // for refresh value of all category dropdowns
    //that.setState({risk_cat_arr:[]}); // this.state.risk_cat_arr[item.id]

    var qus_id = e.target.value;
    if (qus_id !== "" && qus_id != undefined) {
      // call API to get question config details
      this.props.dispatch(getQuestionConfigById(e.target.value));
      // call API to get question details
      this.props.dispatch(getQuestionById(e.target.value));

      that.setState({ qus_id: qus_id, qusConfigForm: false, qusForm: true });

      {
        /* that.setState({
                        btnAddloading2: !that.state.btnAddloading2,
                        progress: 0.5,
                    }); */
      }

      that.setState({
        btnAddloading2: false,
        alertMessageVisible: false,
        undefined_msg: false,
      });
      //this.refs.scroll_div.scrollIntoView();
    } else {
      that.setState({
        modal: true,
        errorEmail: true,
        alertMessageVisible: false,
        undefined_msg: true,
      });
    }
    that.validator.hideMessages();
    this.refs.scroll_div.scrollIntoView();
  };

  clickBtnAddConfigData = (e) => {
    var that = this;
    if (that.validator.allValid()) {
    } else {
      that.validator.showMessages();
      // rerender to show messages for the first time
      that.forceUpdate();
    }

    var qus_id = this.state.qus_id;
    //console.log("qus_id: "+qus_id);
    if (qus_id != "") {
      var risk_cat_arr = this.state.risk_cat_arr; // ,1-2,2-1,3-3
      //console.log("get risk_cat_arr: "+risk_cat_arr);
      //console.log("get risk_cat_arr_length: "+risk_cat_arr.length);

      var final_cat_arr = [];
      risk_cat_arr.forEach((item, i) => {
        if (item != "") {
          final_cat_arr.push(item);
        }
      });
      //console.log("final_cat_arr: "+final_cat_arr);
      var count_arr = final_cat_arr.length;
      //console.log("count_arr: "+count_arr);

      var area_risk_type_data = [];
      var area_risk_count = this.props.area_risk_type_data.length;
      //console.log("area_risk_count: "+area_risk_count);

      if (area_risk_count != count_arr) {
        // Not equal length
        this.validator.showMessages();
      } // equal length
      else {
        this.validator.hideMessages();
        var config_flag = that.state.config_flag;
        console.log("config_flag: " + config_flag);
        for (var k = 0; k < count_arr; k++) {
          var cat_item = final_cat_arr[k]; // 1-2
          var split_cat_item = cat_item.split("-");
          var area_risk_type_id = split_cat_item[0];
          var que_cat_id = split_cat_item[1];
          var post = {
            que_id: qus_id,
            area_risk_type_id: area_risk_type_id,
            que_cat_id: que_cat_id,
          };

          // to check whether add or update config data
          if (config_flag === true) {
            // update data
            console.log("update data");
            // call API to add question config data
            this.props.dispatch(updateQuestionConfigById(post));
          } // add data
          else {
            console.log("add data");
            // call API to add question config data
            this.props.dispatch(addQuestionConfigData(post));
          }
        }
      }
    } else {
      var risk_cat_arr = "";
    }
  };

  toogleWhyModelClose() {
    this.setState({
      whyFlag: true,
    });
  }
  toogleWhereModelClose() {
    this.setState({
      whereFlag: true,
    });
  }

  onDismiss() {
    var that = this;
    that.setState({
      alertMessageVisible: false,
    });
  }

  /*all event and state*/

  render() {
    var that = this;
    const { dispatch, state_data, question_data, question_where_data, question_why_data, area_risk_type_data, question_category_data } = this.props;

    const ViewContent = ({ children }) => <div className="view-content view-components">{children}</div>;

    const ViewHeader = () => (
      <div className="view-header">
        <header className="title text-white">
          <h1 style={{ color: "white" }} className="h4 text-uppercase">
            {" "}
            Questions{" "}
          </h1>
          <p className="mb-0"> Manage audit related questions here </p>
        </header>
      </div>
    );

    const StateFacilityDropDownList = () => (
      <div className="row">
        <div className="col-md-4 marBtm_20">
          <h6 className="mb-4 text-uppercase"> Select State </h6>
          <StateListClass stateValue={this.state.drpStateValue} drpChangeState={this.drpClickState} stateFillData={state_data} />
          <span className="error-text">{this.validator.message("State", this.state.drpStateValue, "required")}</span>
        </div>

        <div className="col-md-4 btnmarginTop">
          <LaddaButton
            className={this.state.errorEmail === "true" ? "input-border-red" : "btn btn-warning load-que-btn marginRight_10"}
            loading={this.state.loading}
            onClick={this.btnLoadQuestion}
            data-color="#eee"
            data-size={S}
            data-style={EXPAND_RIGHT}
            data-spinner-size={30}
            data-spinner-color="#ddd"
            data-spinner-lines={12}
          >
            Load Question
          </LaddaButton>
        </div>
      </div>
    );

    const AlertMessage = () => (
      <AlertMessageClass
        alertMessageColor={this.state.alertMessageColor}
        alertMessageVisible={this.state.alertMessageVisible}
        onDismiss={this.onDismiss}
        alertMessageText={this.state.alertMessageText}
      />
    );

    const QuestionTable = () => (
      <QuestionTableClass
        questionFillData={question_data}
        toggleModal={this.toggleEditQuestionModal}
        toggleMaster={this.btnLoadMaster}
        toggleDeleteModal={this.toggleQuesDeleteModal}
        toggleConfigureModal={this.toggleConfigureModal}
      />
    );

    const MasterTable = () => (
      <MasterTableClass
        whereFillData={question_where_data}
        whyFillData={question_why_data}
        master_que_text={this.state.master_que_text}
        toggleWhyModal={this.toggleWhyModal}
        toggleEditWhyModal={this.toggleEditWhyModal}
        toggleWhereModal={this.toggleWhereModal}
        toggleEditWhereModal={this.toggleEditWhereModal}
        toggleDeleteModal={this.toggleDeleteModal}
        toggleWhyDeleteModal={this.toggleWhyDeleteModal}
        toggleWhereDeleteModal={this.toggleWhereDeleteModal}
      />
    );

    const DefaultTable = () => (
      <table className="table">
        <thead>
          <tr>
            {/*<th> Id </th> */}
            <th> Question Text </th>
            <th> Help Text </th>
            <th> Action </th>
          </tr>
        </thead>

        <tbody></tbody>
      </table>
    );

    return (
      <div className="view">
        <ViewHeader />

        <ViewContent>
          <Card className="mb-4">
            <CardBlock className="table-responsive">
              <StateFacilityDropDownList />
            </CardBlock>
          </Card>
          <br />

          <div className="row pull-right marginBtm" hidden={this.state.newQus}>
            <div className="col-md-12">
              <button className="btn btn-sm btn-warning pointerClass" value="btnadd" onClick={this.AddtoogleQuestionModel}>
                <i className="fa fa-plus lg"></i> &nbsp; Add New Question{" "}
              </button>
            </div>
          </div>
          <br />

          <Card className="mb-4 col-md-12" hidden={this.state.qusForm}>
            <div className="row">
              <CardBlock className="table-responsive">
                <h6 className="mb-4 text-uppercase text-center"> {this.state.btnName} Audit Questions</h6>

                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6 offset-md-3">
                      <FormGroup>
                        <Label>Question Text</Label>
                        <input
                          type="textarea"
                          name="quesText"
                          value={this.state.question_text}
                          id="quesText"
                          className="form-control-danger form-control"
                          onChange={this.changeTxtAddQues}
                          placeholder="Question Text"
                          autoFocus={this.state.setQusFocus}
                          onBlur={this.removeFocus}
                          maxLength="480"
                        />
                        <span className="error-text">
                          {this.validator.message("question text", this.state.question_text, "required|custom_text", false, {
                            custom_text: "This field may only contain alphabetic characters.",
                            default: "This field is required.",
                          })}
                        </span>
                      </FormGroup>
                    </div>

                    <div className="col-md-6 offset-md-3">
                      <FormGroup>
                        <Label>Question Help Text</Label>
                        <input
                          type="textarea"
                          value={this.state.question_info}
                          name="qusInfo"
                          id="qusInfo"
                          className="form-control-danger form-control"
                          onChange={this.changeTxtAddQusInfo}
                          placeholder="Question help text"
                          autoFocus={this.state.setInfoFocus}
                          onBlur={this.removeFocus}
                          maxLength="480"
                        />
                        <span className="error-text">
                          {this.validator.message("question help text", this.state.question_info, "required|custom_text", false, {
                            custom_text: "This field may only contain alphabetic characters.",
                            default: "This field is required.",
                          })}
                        </span>
                      </FormGroup>
                    </div>

                    <div className="col-md-12 text-center">
                      <LaddaButton
                        className="btn btn-primary"
                        loading={this.state.btnAddloading}
                        onClick={this.clickBtnAddQus}
                        data-color="#eee"
                        data-size={S}
                        data-style={EXPAND_RIGHT}
                        data-spinner-size={30}
                        data-spinner-color="#ddd"
                        data-spinner-lines={12}
                      >
                        {" "}
                        {this.state.btnName}{" "}
                      </LaddaButton>
                      &nbsp;
                      <Button color="default" className="pointerClass cancel_btn" onClick={this.toogleModelClose}>
                        {" "}
                        Cancel{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBlock>
            </div>
          </Card>

          {/* modal for add question configuration */}
          <Card className="mb-4 col-md-12" hidden={this.state.qusConfigForm}>
            <div className="row">
              <CardBlock className="table-responsive">
                <h6 className="mb-4 text-uppercase text-center">Audit Question Configuration</h6>
                <div className="offset-md-2 col-md-8">
                  <hr />
                  <strong>Question: </strong>
                  {this.state.master_que_text}
                </div>
                <div className="offset-md-2"></div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6 offset-md-3">
                      <table className="table marTop_20">
                        <thead>
                          <tr>
                            <th> Area Risk Type </th>
                            <th> Category </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.area_risk_type_data.map((item, i) => (
                            <tr key={item.id} id={item.id}>
                              <td>{item.name} </td>
                              <td>
                                <Input type="select" name="drpCatValue" id={item.id} className="custom_select" value={that.state.risk_cat_arr[item.id]} onChange={that.drpChangeCat}>
                                  <option value="">--Select Category--</option>
                                  {this.props.question_category_data.map((cat_item, c) => (
                                    <option key={cat_item.id} value={item.id + "-" + cat_item.id}>
                                      {cat_item.risk_cat}
                                    </option>
                                  ))}
                                </Input>
                                <span className="error-text">{this.validator.message("category", this.state.risk_cat_arr[item.id], "required")}</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-12 text-center">
                      <LaddaButton
                        className="btn btn-primary"
                        onClick={this.clickBtnAddConfigData}
                        loading={this.state.btnAddloading}
                        data-color="#eee"
                        data-size={S}
                        data-style={EXPAND_RIGHT}
                        data-spinner-size={30}
                        data-spinner-color="#ddd"
                        data-spinner-lines={12}
                      >
                        Submit
                      </LaddaButton>
                      &nbsp;
                      <Button color="default" className="btn pointerClass cancel_btn" onClick={this.toogleConfigModelClose}>
                        {" "}
                        Cancel{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBlock>
            </div>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.newQus}>
            <CardBlock className="table-responsive">
              <AlertMessage />
              {/* <Alert color="success">This is a success alert — check it out!</Alert> */}
              <AlertMessageClass alertMessageColor="danger" alertMessageVisible={this.state.undefined_msg} onDismiss={this.onDismiss} alertMessageText="Something went wrong. Please try again." />

              <h6 className="mb-4 text-uppercase" ref="scroll_div">
                {" "}
                Manage Audit Questions{" "}
              </h6>
              <LoaderClass visibleStatus={this.state.loaderStatus} />
              <div>
                <QuestionTable />
              </div>
            </CardBlock>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.showDefaultTable}>
            <CardBlock className="table-responsive">
              <h6 className="mb-4 text-uppercase"> Manage Audit Questions </h6>
              <DefaultTable />
            </CardBlock>
          </Card>

          <div hidden={this.state.disMasForm} className="marBtm_20">
            <button className="btn btn-sm btn-warning pointerClass marginRight" value="" onClick={this.backToQueList}>
              <i className="fa fa-arrow-left lg"></i> &nbsp; Back to Questions List{" "}
            </button>
          </div>

          {/* add/edit why modal */}
          <Card className="mb-4 col-md-12" hidden={this.state.whyFlag}>
            <div className="row">
              <CardBlock className="table-responsive">
                <h6 className="mb-4 text-uppercase text-center"> {this.state.btnWhyName} New Why Comment</h6>

                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6 offset-md-3">
                      <FormGroup>
                        <Label>Why Comment Text</Label>
                        <Input
                          type="textarea"
                          name="why_name"
                          value={this.state.why_name}
                          id="why_name"
                          className="form-control"
                          placeholder="Why Comment Text"
                          onChange={this.changeTxtAddWhy}
                          autoFocus={this.state.setWhyFocus}
                          maxLength="200"
                        />
                        <span className="error-text">
                          {this.validator.message("why comment text", this.state.why_name, "required|custom_text", false, {
                            custom_text: "This field may only contain alphabetic characters and number.",
                            default: "This field is required.",
                          })}
                        </span>
                      </FormGroup>
                    </div>

                    <div className="col-md-12 text-center">
                      <LaddaButton
                        className="btn btn-primary"
                        onClick={this.clickBtnAddWhy}
                        loading={this.state.btnAddloading2}
                        data-color="#eee"
                        data-size={S}
                        data-style={EXPAND_RIGHT}
                        data-spinner-size={30}
                        data-spinner-color="#ddd"
                        data-spinner-lines={12}
                      >
                        {" "}
                        {this.state.btnWhyName}{" "}
                      </LaddaButton>
                      &nbsp;
                      <Button color="default" className="btn pointerClass cancel_btn" onClick={this.toogleWhyModelClose}>
                        {" "}
                        Cancel{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBlock>
            </div>
          </Card>

          {/* add/edit where modal */}
          <Card className="mb-4 col-md-12" hidden={this.state.whereFlag}>
            <div className="row">
              <CardBlock className="table-responsive">
                <h6 className="mb-4 text-uppercase text-center"> {this.state.btnWhereName} New Where Comment</h6>

                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6 offset-md-3">
                      <FormGroup>
                        <Label>Where Comment Text</Label>
                        <Input
                          type="textarea"
                          name="where_name"
                          value={this.state.where_name}
                          id="where_name"
                          className="form-control"
                          placeholder="Where Comment Text"
                          onChange={this.changeTxtAddWhere}
                          autoFocus={this.state.setWhereFocus}
                          maxLength="200"
                        />
                        <span className="error-text">
                          {this.validator.message("where comment text", this.state.where_name, "required|custom_text", false, {
                            custom_text: "This field may only contain alphabetic characters and number.",
                            default: "This field is required.",
                          })}
                        </span>
                      </FormGroup>
                    </div>

                    <div className="col-md-12 text-center">
                      <LaddaButton
                        className="btn btn-primary"
                        onClick={this.clickBtnAddWhere}
                        loading={this.state.btnAddloading2}
                        data-color="#eee"
                        data-size={S}
                        data-style={EXPAND_RIGHT}
                        data-spinner-size={30}
                        data-spinner-color="#ddd"
                        data-spinner-lines={12}
                      >
                        {" "}
                        {this.state.btnWhereName}{" "}
                      </LaddaButton>
                      &nbsp;
                      <Button color="default" className="btn pointerClass cancel_btn" onClick={this.toogleWhereModelClose}>
                        {" "}
                        Cancel{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBlock>
            </div>
          </Card>

          <div hidden={this.state.disMasForm}>
            {/*<button className = "btn btn-sm btn-warning pointerClass marginRight" value="" onClick = {this.backToQueList}><i className="fa fa-arrow-left lg"></i> &nbsp; Back to Questions List </button>
             */}
            <h6 className="mb-4 text-uppercase text-center padTop"> Manage Master </h6>
            <Card className="mb-4 col-md-12">
              <CardBlock className="table-responsive">
                <AlertMessage />
                <MasterTable />

                <LoaderClass visibleStatus={this.state.loaderStatus} />
              </CardBlock>
            </Card>
          </div>

          {/*<LoaderClass visibleStatus={this.state.loaderStatus}/>*/}

          {/*
                                         <Modal isOpen={false} toggle={this.toggleWhyModal} >
                                                <ModalHeader toggle={this.toggleWhyModal}>Add New Why Comment</ModalHeader>
                                                <ModalBody>
                                                   <Input type = "text" name = "why_name" value = {this.state.why_name}
                                                    id = "why_name"  className="form-control" onChange = {this.changeTxtAddWhy} placeholder = "Why Comment Text"  autoFocus={this.state.setWhyFocus} onBlur={this.removeFocus} maxLength="100"/>  
                                                    <span className="error-text">{this.validator.message('why comment text', this.state.why_name, 'required')}</span>
                                                    
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="primary" onClick={this.toggleWhyModal}>{this.state.btnNameWhy}</Button>{' '}
                                                    <Button color="default" className="pointerClass" onClick={this.toggleWhyModal}>Cancel</Button>
                                                </ModalFooter>
                                         </Modal>
                                         <Modal isOpen={this.state.popWhereModal} toggle={this.toggleWhereModal} >
                                                <ModalHeader toggle={this.toggleWhereModal}>Add New Where Comment</ModalHeader>
                                                <ModalBody>
                                                   <Input type = "text" name = "where_name" value = {this.state.where_name}
                                                    id = "where_name"  className="form-control" onChange = {this.changeTxtAddWhere} placeholder = "Where Comment Text"  autoFocus={this.state.setQusFocus} onBlur={this.removeFocus} maxLength="100"/>  
                                                    <span className="error-text">{this.validator.message('where comment text', this.state.where_name, 'required')}</span>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="primary" onClick={this.toggleWhereModal}>{this.state.btnNameWhere}</Button>{' '}
                                                    <Button color="default" className="pointerClass" onClick={this.toggleWhereModal}>Cancel</Button>
                                                </ModalFooter>
                                         </Modal>
                                         */}

          <Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal}>
            <ModalHeader toggle={this.toggleDeleteModal}>Confirmation Alert</ModalHeader>
            <ModalBody>Are you sure you want to delete ?</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.clickBtnDelete}>
                Yes
              </Button>{" "}
              <Button color="" className="pointerClass btn btn-warning" onClick={this.toogleDeleteModelClose}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </ViewContent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { postsBySubreddit } = state;
  const {
    state_data: state_data,
    question_data: question_data,
    question_add_data: question_add_data,
    question_delete_data: question_delete_data,
    fetch_question_by_id: fetch_question_by_id,
    question_update_data: question_update_data,
    question_where_data: question_where_data,
    question_add_where_data: question_add_where_data,
    where_delete_data: where_delete_data,
    fetch_where_by_id: fetch_where_by_id,
    where_update_data: where_update_data,
    question_why_data: question_why_data,
    question_add_why_data: question_add_why_data,
    why_delete_data: why_delete_data,
    fetch_why_by_id: fetch_why_by_id,
    why_update_data: why_update_data,
    area_risk_type_data: area_risk_type_data,
    question_category_data: question_category_data,
    question_add_config_data: question_add_config_data,
    fetch_question_config_by_id: fetch_question_config_by_id,
    question_update_config_data: question_update_config_data,
  } = postsBySubreddit;

  return {
    state_data,
    question_data,
    question_add_data,
    question_delete_data,
    fetch_question_by_id,
    question_update_data,
    question_where_data,
    question_add_where_data,
    where_delete_data,
    fetch_where_by_id,
    where_update_data,
    question_why_data,
    question_add_why_data,
    why_delete_data,
    fetch_why_by_id,
    why_update_data,
    area_risk_type_data,
    question_category_data,
    question_add_config_data,
    fetch_question_config_by_id,
    question_update_config_data,
  };
}

const AppContainer = connect(mapStateToProps)(Questions);

export default AppContainer;
