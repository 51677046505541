import axios from "axios";
import React, { Component } from "react";
import { browserHistory } from "react-router";
import { CLIENT_ID, TOKEN } from "../../../utils/constants/localstoregeConstants";

class AutoLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("token");
    const clientId = queryParams.get("clientId");

    localStorage.setItem(CLIENT_ID, clientId);

    var post = {
      token_id: id,
    };
    if (id && id !== "") {
      // localStorage.setItem("ck_name", id);
      axios
        .post("/api/cleaning_auto_login", post, { headers: { "token-id": id, "device-type": process.env.REACT_APP_DEVICE_TYPE } })
        .then(function (response) {
          if (response.data != "" && response.data != null) {
            localStorage.setItem(TOKEN, response.data.result.token_id);
            browserHistory.push("/dashboard");
            window.location.reload();
          } else {
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error, "myeoor");
            if (error.response.status === 403) {
            } else {
              console.log(error);
            }
          } else {
            console.log(error);
          }
        });
    }
  }

  render() {
    return <div></div>;
  }
}

export default AutoLogin;
