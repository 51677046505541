import { CLIENT_ID, TOKEN } from "../constants/localstoregeConstants";

export const getAllRequiredHeaders = ({ rbu = "", ruep = "", url }) => {
  // rbu = request base url
  // ruep = request url end point

  let headers = {};

  headers["rbu"] = rbu;

  if (url.includes("managed_cleaning_users")) {
    headers["token-id"] = 0;
  } else {
    headers["token-id"] = localStorage.getItem(TOKEN);
    headers["client-id"] = localStorage.getItem(CLIENT_ID);
  }

  headers["device-type"] = process.env.REACT_APP_DEVICE_TYPE;

  return {
    ...headers,
  };
};
